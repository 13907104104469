$backgroundColor: #ffffff;
$bodyColor: #000000;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";


body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $bodyFont;
  font-size: 20px;
}
